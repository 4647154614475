import React from 'react'

import {
  Container,
  SearchAnimatedImage,
  Title
} from './styles'

const LoadingContainer: React.FC = ({ children }) => (
  <Container>
    <SearchAnimatedImage />
    {/* <Loader /> */}

    <Title>
      {children}
    </Title>
  </Container>
)

export default LoadingContainer
