import React, {
  lazy,
  Suspense
} from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

import { Loader } from '@grupo-tiradentes/web'

const NotFoundPage = lazy(() => import('../pages/NotFoundPage/NotFoundPage'))
const DIF = lazy(() => import('../pages/PaymentDetailsPage/PaymentDetailsPage'))
const BoletoPage = lazy(() => import('../pages/BoletoPage/BoletoPage'))
const NotFoundDataPage = lazy(() => import('../pages/NotFoundDataPage/NotFoundDataPage'))
const NotAutorizedPage = lazy(() => import('../pages/NotAutorizedPage/NotAutorizedPage'))

const routes = (): JSX.Element => (
  <BrowserRouter>
    <Suspense fallback={ <Loader /> }>
      <Switch>
        <Route exact path="/dif/detalhes-pagamento/:id" component={ DIF } />
        <Route exact path="/dif/pagamento/:id" component={ BoletoPage } />
        <Route exact path="/dif/aluno-nao-encontrado" component={ NotFoundDataPage } />
        <Route exact path="/dif/nao-autorizado/:statusAluno/:statusProcesso" component={ NotAutorizedPage } />
        <Route path="/" component={ NotFoundPage } />
      </Switch>
    </Suspense>
  </BrowserRouter>
)

export default routes
