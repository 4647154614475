import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { LoadingProvider } from './providers/LoadingProvider'
import Routes from './routes/routes'

export default (): JSX.Element => (
  <>
    {/* <ReactKeycloakProvider authClient={ keycloak } initOptions={ keycloakProviderInitConfig }> */}
    <LoadingProvider>
      <Routes />
      <ToastContainer theme="colored" />
    </LoadingProvider>
    {/* </ReactKeycloakProvider> */}
  </>
)
