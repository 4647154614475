import React, {
  createContext,
  useState
} from 'react'

import LoadingContainer from '../containers/LoadingContainer/LoadingContainer'

export type LoadingTypes = {
  isLoading?: boolean;
  loadingText?: JSX.Element | string | null;
  startLoading?: () => void,
  stopLoading?: () => void,
  setLoadingText?: (text: JSX.Element | string | null) => void,
}

export const LoadingContext = createContext<LoadingTypes>({
  startLoading: () => null,
  stopLoading: () => null,
  setLoadingText: () => null,
})

export const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingText, setLoadingText] = useState<JSX.Element | string | null>('Estamos buscando seus dados...')

  const startLoading = () => setIsLoading(true)

  const stopLoading = () => setIsLoading(false)

  return (
    <LoadingContext.Provider value={ {
      isLoading,
      loadingText,
      startLoading,
      stopLoading,
      setLoadingText,
    } }
    >
      { children }
      { isLoading && (<LoadingContainer>{loadingText}</LoadingContainer>) }
    </LoadingContext.Provider>
  )
}
