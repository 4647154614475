import styled from 'styled-components'

import { ReactComponent as SearchImage } from '../../assets/img/search-icon.svg'

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
`

export const SearchAnimatedImage = styled(SearchImage)`
  height: 100%;
  height: 20%;
  width: auto;
  animation: swing 1s alternate infinite ease-in-out;
  transition: height .3s;
  max-height: 200px;

  @keyframes swing {
    from {
      transform: rotate(-5deg);
    }
    to {
      transform: rotate(5deg);
    }
  }
  
  &:hover {
    height: 31%;
  }
`

export const Title = styled.h3`
  color: #737477;
  text-align: center;
  margin: 30px 0 10px 0;
`
